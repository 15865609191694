// @flow
import * as actions from '../actions/';
import type { CartState } from '../types';

const intialState: CartState = {
  isFetching: false,
  cartItems: [],
};

export const cart = (
  state: CartState = intialState,
  action: Object
): CartState => {
  switch (action.type) {
    case actions.ADD_TO_CART_REQUEST:
    case actions.APPLY_COUPON_REQUEST:
    case actions.REMOVE_FROM_CART_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case 'ADD_TO_CART_SUCCESS':
      if (
        state.cartItems.some(function (el) {
          return el.id === action.payload.id;
        })
      ) {
        return {
          ...state,
          cartItems: [...state.cartItems],
          isFetching: false,
        };
      } else {
        return {
          ...state,
          cartItems: [
            ...state.cartItems,
            {
              ...action.payload,
            },
          ],
          isFetching: false,
        };
      }
    case actions.PROCESS_ORDER_CLEAR_LAST:
      return {
        ...state,
        isFetching: false,
        cartItems: [],
      };
    case actions.ADD_TO_CART_FAILURE:
    case actions.REMOVE_FROM_CART_FAILURE:
    case actions.APPLY_COUPON_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.REMOVE_FROM_CART_SUCCESS:
      var haveDiscount = false;
      // $FlowFixMe
      var cartItemsObj = state.cartItems.filter(function (obj) {
        // if the items have discount amount then keep the coupon code state,
        // otherwise make it null
        // $FlowFixMe
        if (obj.discountAmount && action.payload.id !== obj.id) {
          haveDiscount = true;
        }
        return obj.id !== action.payload.id;
      });

      return {
        ...state,
        cartItems: cartItemsObj,
        couponValid: haveDiscount ? state.couponValid : false,
        couponCode: haveDiscount ? state.couponCode : null,
        isFetching: false,
      };
    case actions.APPLY_COUPON_SUCCESS:
      return {
        ...state,
        cartItems: [...action.payload.response.cart],
        couponValid: action.payload.response.couponValid,
        couponCode: action.payload.response.couponValid
          ? action.payload.response.couponCode
          : null,
        isFetching: false,
        lastError: null,
      };
    case actions.PROCESS_ORDER_SUCCESS:
      return {
        ...state,
        couponValid: false,
        couponCode: null,
      };
    case actions.SET_CART:
      return {
        ...state,
        cartItems: [...action.payload],
      };
    case 'LOG_OUT_SUCCESS':
      return intialState;
    default:
      return state;
  }
};
