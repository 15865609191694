// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Action, Dispatch, Product } from '../../types';

export const REMOVE_FROM_CART_REQUEST = 'REMOVE_FROM_CART_REQUEST';
export const REMOVE_FROM_CART_SUCCESS = 'REMOVE_FROM_CART_SUCCESS';
export const REMOVE_FROM_CART_FAILURE = 'REMOVE_FROM_CART_FAILURE';

const removeFromCartRequest = (): Action => {
  return {
    type: REMOVE_FROM_CART_REQUEST
  };
};

const removeFromCartSuccess = (product: Product): Action => {
  return {
    type: REMOVE_FROM_CART_SUCCESS,
    payload: product,
    action: 'remove'
  };
};

const removeFromCartFailure = (error: string): Action => {
  return {
    type: REMOVE_FROM_CART_FAILURE,
    error
  };
};

export const removeFromCart = (product: Product): ThunkAction => async (
  dispatch: Dispatch,
  getState
) => {
  try {
    dispatch(removeFromCartRequest());
    dispatch(removeFromCartSuccess(product));
  } catch (error) {
    Sentry.captureException(error);
    dispatch(removeFromCartFailure(error));
    return error;
  }
};
