// @flow
import * as React from 'react';
import { formatCurrency } from '../helpers';

type Props = {
  country: ?string,
  price: number,
};

class ProductInfoFields extends React.PureComponent<Props> {
  render(): React.Element<'div'> {
    const { country, price } = this.props;
    return (
      <div className="product-card__header-info-inner-wrapper">
        <div className="product-card__info">
          <span className="product-card__info-field">
            <strong>Price:</strong>
            &nbsp; {formatCurrency(price, undefined, undefined, 0)}
          </span>
          <span className="product-card__info-field">
            <strong>Country: </strong>
            {country ? country : 'N/A'}
          </span>
        </div>
      </div>
    );
  }
}

export default ProductInfoFields;
