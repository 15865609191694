// @flow
import * as actions from '../actions/pages/homepage';
// import type { Action, State } from '../types';

const intialState = {
  isFetching: false
};

export const homepageContent = (
  state: Object = intialState,
  action: Object
): any => {
  switch (action.type) {
    case actions.SET_HOMEPAGE_CONTENT_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case actions.SET_HOMEPAGE_CONTENT_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    case actions.SET_HOMEPAGE_CONTENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false
      };
    default:
      return state;
  }
};
