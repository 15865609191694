//@flow
import React from 'react';
import { Button, Form, Input } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { newsletterSignup } from '../actions/pages/campaignMonitor';

type Props = {
  newsletterSignup: Function
};

type State = {
  name: string,
  email: string,
  emailConfirm: string,
  message: string,
  messageClass: string,
  messageStatus: boolean,
  isLoading: boolean
};

class NewsletterSignup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      emailConfirm: '',
      message: '',
      messageClass: '',
      messageStatus: false,
      isLoading: false
    };
  }

  async submitForm(ev) {
    ev.preventDefault();
    const { name, email, emailConfirm } = this.state;

    this.setState({
      isLoading: true
    });

    if (email === emailConfirm) {
      this.props.newsletterSignup(name, email).then(response => {
        if (response) {
          this.setState({
            name: '',
            email: '',
            emailConfirm: '',
            messageStatus: true,
            message: "You've been signed up",
            messageClass: 'valid',
            isLoading: false
          });
        } else {
          this.setState({
            isLoading: false
          });
        }
      });
    } else {
      this.setState({
        messageStatus: true,
        message: 'Email and confirm email must match!',
        messageClass: 'invalid',
        isLoading: false
      });
    }
  }

  handleKeyPressName = (event, { value }) => {
    this.setState({ name: value });
  };

  handleKeyPressEmail = (event, { value }) => {
    this.setState({ email: value });
  };

  handleKeyPressEmailConfirm = (event, { value }) => {
    this.setState({ emailConfirm: value });
  };

  render() {
    const {
      name,
      email,
      emailConfirm,
      message,
      messageClass,
      messageStatus
    } = this.state;
    return (
      <div className="newsletter-signup">
        <Form
          onSubmit={this.submitForm.bind(this)}
          className="newsletter-signup__form"
        >
          <Form.Field>
            <Input
              placeholder={'Name'}
              type="text"
              onChange={this.handleKeyPressName}
              value={name}
              required
            />
          </Form.Field>
          <Form.Field>
            <Input
              placeholder={'Email'}
              type="email"
              onChange={this.handleKeyPressEmail}
              value={email}
              required
            />
          </Form.Field>
          <Form.Field>
            <Input
              type="email"
              placeholder={'Confirm Email'}
              onChange={this.handleKeyPressEmailConfirm}
              value={emailConfirm}
              required
            />
          </Form.Field>
          <Button
            className="am_product_signup"
            type="submit"
            loading={this.state.isLoading}
            fluid
            disabled={this.state.isLoading}
          >
            Sign up to Market Updates
          </Button>
        </Form>
        {messageStatus && (
          <div className={messageClass + '  message'}>{message}</div>
        )}
      </div>
    );
  }
}

// export default NewsletterSignup;

const mapStateToProps = function(state) {
  return {};
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      newsletterSignup
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsletterSignup): any);
