// @flow
import * as React from 'react';
import { NavMenu } from 'roy-morgan-header';
import { Menu } from 'semantic-ui-react';
import { ONLINE_STORE_PRODUCT } from '../helpers';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import onlineStoreLogo from '../assets/images/logo.png';
import cartIcon from '../assets/images/cart.png';
import { removeFromCart } from '../actions/cart/removeFromCart';
import type { Dispatch } from '../types';
import { organisationChange, teamChange } from '../actions';
import { switchTo } from 'roy-morgan-auth';
import type { User, Organisation, Team } from '../types';

type Props = {
  authUser: User,
  location: {
    pathname: string,
  },
  cartItems: Array<Object>,
  removeFromCart: Function,
  products: any,
  organisations: Organisation[],
  organisationChange: (organisationId: number) => Promise<void>,
  currentOrganisation: number,
  teams: Team[],
  teamChange: (teamId: number) => Promise<void>,
  currentTeam: number,
  switchTo: (
    email: string,
    switchFromEmail: string,
    switchFromToken: string
  ) => Promise<void>,
};

class Header extends React.Component<Props> {
  removeFromCart = (event, product) => {
    // console.log('Click happened');
    this.props.removeFromCart(product);
  };

  render() {
    const {
      authUser,
      location,
      cartItems,
      products,
      organisations,
      teams,
      organisationChange,
      teamChange,
      currentOrganisation,
      currentTeam,
      switchTo,
    } = this.props;

    const { pathname } = location;

    let showLogin = true;

    const loginPage = pathname === '/login';
    if (authUser || loginPage) {
      showLogin = false;
    }

    return (
      <NavMenu
        logoUrl={onlineStoreLogo}
        authUser={authUser}
        publicNav
        showLogin={showLogin}
        pathname={pathname}
        cartItems={cartItems}
        cartIcon={cartIcon}
        removeFromCart={this.removeFromCart}
        onlineStoreAccountURL={'/my-account/details'}
        products={products}
        productFamilyId={ONLINE_STORE_PRODUCT}
        organisations={organisations}
        currentOrganisation={currentOrganisation}
        organisationChange={organisationChange}
        teams={teams}
        currentTeam={currentTeam}
        teamChange={teamChange}
        switchTo={switchTo}
        userMenuBreakpoint={1620}
        mobileBreakpoint={1100}
      >
        <Menu.Menu>
          <Menu.Item
            as={NavLink}
            exact
            to="/"
            name="Home"
            className="nav-menu__nav-link"
            activeClassName="nav-menu__nav-link--active"
          />
          <Menu.Item
            as={NavLink}
            exact
            to="/products"
            name="Products"
            className="nav-menu__nav-link"
            activeClassName="nav-menu__nav-link--active"
          />
          <Menu.Item
            as={NavLink}
            exact
            to="/subscriptions-and-packages"
            name="Subscriptions and Packages"
            className="nav-menu__nav-link"
            activeClassName="nav-menu__nav-link--active"
          />
          <Menu.Item
            as={NavLink}
            exact
            to="/product-descriptions"
            name="Product Descriptions"
            className="nav-menu__nav-link"
            activeClassName="nav-menu__nav-link--active"
          />
          <Menu.Item
            as={NavLink}
            exact
            to="/faq"
            name="FAQ"
            className="nav-menu__nav-link"
            activeClassName="nav-menu__nav-link--active"
          />
          {/* TODO: need to make a contact page with forms like live management then uncomment below code */}
          {/* <Menu.Item
            as={NavLink}
            exact
            to="/contact"
            name="Contact Us"
            className="nav-menu__nav-link"
            activeClassName="nav-menu__nav-link--active"
          /> */}
        </Menu.Menu>
      </NavMenu>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    authUser: state.auth.user.user,
    cartItems: state.cart.cartItems,
    products: state.auth.products,
    currentOrganisation: state.auth.organisations.currentOrganisation,
    organisations: Object.keys(state.auth.organisations.byId).map(
      (i) => state.auth.organisations.byId[i]
    ),
    currentTeam: state.auth.teams.currentTeam,
    teams: Object.keys(state.auth.teams.byId).map(
      (i) => state.auth.teams.byId[i]
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      // setProductList,
      removeFromCart,
      organisationChange,
      teamChange,
      switchTo,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(Header): React.AbstractComponent<Props>);
