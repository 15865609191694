// @flow
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import FlashMessage from './FlashMessage';
import UserSettingsForm from '../components/UserSettingsForm';
import { updateUser } from '../actions/user/updateUser';

import type { State as AppState, Dispatch } from '../types';

type User = {
  name?: string,
  email?: string,
  phone?: string,
};

type Props = {
  user: User,
  updateUser: (formData: User) => Promise<null>,
};

class Settings extends React.Component<Props> {
  handleUpdate = (formData) => {
    this.props.updateUser(formData);
  };

  render() {
    return (
      <div>
        <FlashMessage />
        <h3 className="light-title">My Details</h3>
        <UserSettingsForm
          handleFormSubmit={this.handleUpdate}
          user={this.props.user}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props) => ({
  user: state.auth.user.user,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateUser,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(Settings): any);
