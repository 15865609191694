// @flow
import * as Sentry from '@sentry/browser';
import { callAPI } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE';

function getOrderRequest() {
  return {
    type: GET_ORDER_REQUEST
  };
}

function getOrderSuccess(order: string) {
  return {
    type: GET_ORDER_SUCCESS,
    payload: order
  };
}

function getOrderFailure(error: string) {
  return {
    type: GET_ORDER_FAILURE,
    error: error
  };
}

export const getOrder = (orderId: any): any => async (dispatch, getState) => {
  try {
    dispatch(getOrderRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL('v2', 'store')}/public/orders/${orderId}`,
      isLiveAPICall: true,
      useToken: true
    });
    dispatch(getOrderSuccess(response));
    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getOrderFailure(error.message));
    return error;
  }
};
