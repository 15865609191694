// @flow
// import type { Action } from '../../types';

export const MESSAGES_RESET = 'MESSAGES_RESET';

export const resetMessages = (): {|type: string|} => {
  return {
    type: MESSAGES_RESET
  };
};
