// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Action, Product } from '../../types';

export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE';

const addToCartRequest = (): Action => {
  return {
    type: ADD_TO_CART_REQUEST
  };
};

const addToCartSuccess = (product: Product): Action => {
  return {
    type: ADD_TO_CART_SUCCESS,
    payload: product,
    action: 'add'
  };
};

const addToCartFailure = (error: string): Action => {
  return {
    type: ADD_TO_CART_FAILURE,
    error
  };
};

export const addToCart = (product: Product): ThunkAction => async (
  dispatch,
  getState
) => {
  try {
    dispatch(addToCartRequest());
    dispatch(addToCartSuccess(product));
  } catch (error) {
    Sentry.captureException(error);
    dispatch(addToCartFailure(error));
    return error;
  }
};
