// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetMessages } from '../actions';
import Message from '../components/Message';
import type { State as GlobalState, Action, Dispatch } from '../types';

type Props = {
  content?: string,
  type?: 'error' | 'warning' | 'success',
  resetMessages: () => Action
};

type State = {
  show: boolean
};

class FlashMessage extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      show: true
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        show: true
      });
    }
  }

  onCloseMessage = () => {
    this.setState({
      show: false
    });
    this.props.resetMessages();
  };

  render() {
    const { content, type } = this.props;
    const { show } = this.state;

    if (content) {
      switch (type) {
        case 'error':
          return (
            <Message
              content={content}
              show={show}
              onDismiss={this.onCloseMessage}
              error
            />
          );

        case 'warning':
          return (
            <Message
              content={content}
              show={show}
              onDismiss={this.onCloseMessage}
              warning
            />
          );

        case 'success':
          return (
            <Message
              content={content}
              show={show}
              onDismiss={this.onCloseMessage}
              success
            />
          );

        default:
          return (
            <Message
              content={content}
              show={show}
              onDismiss={this.onCloseMessage}
            />
          );
      }
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = (state: GlobalState) => ({
  content: state.messages.content,
  type: state.messages.type
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      resetMessages
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(FlashMessage): any);
