// @flow
import * as React from 'react';
import { homepageSearch } from '../actions/pages/homepageSearch.js';
import type { Dispatch, HomepageSearchResults } from '../types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Search, Button } from 'semantic-ui-react';
import SearchResultItem from './SearchResultItem';
import debounce from 'lodash.debounce';

type Props = {
  isLoading: boolean,
  searchResults: HomepageSearchResults,
  backgroundUrl: string,
  homepageSearch: (query: string) => Promise<*>
};

type State = {
  value: string
};

class HomepageSearch extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: '',
      isLoading: false
    };

    // make sure the search input doesn't run heaps of requests for fast typists
    this.searchForKeyword = debounce(this.searchForKeyword, 500, {
      leading: true
    });
  }

  UNSAFE_componentWillMount() {
    this.resetComponent();
  }

  resetComponent = () => {
    this.setState({ value: '' });
  };

  handleKeyPress = (event, { value }) => {
    this.setState({ value });
    this.searchForKeyword(value);
  };

  searchForKeyword = value => {
    if (value.length >= 3) {
      this.props.homepageSearch(value);
    }
  };

  handleResultSelect = (event, { result }) => {
    this.setState({ value: result.title });
  };

  handleSearchClick = value => {
    window.location.href = '/products?keyword=' + value;
  };

  render() {
    const { searchResults, isLoading, backgroundUrl } = this.props;
    const { value } = this.state;
    const styles = {
      backgroundImage: `url('${backgroundUrl}')`,
      backgroundSize: 'cover'
    };
    return (
      <div className="ui center aligned homepage-search" style={styles}>
        <div className="homepage-search__wrapper">
          <div className="homepage-search__input-container">
            <div className="homepage-search__search-container">
              <Search
                className="homepage-search__search-input"
                loading={isLoading}
                minCharacters={3}
                onResultSelect={this.handleResultSelect}
                onSearchChange={this.handleKeyPress}
                results={searchResults}
                value={value}
                resultRenderer={({ title }) => (
                  <SearchResultItem title={title} />
                )}
                noResultsMessage={`"${value}"`}
              />
              <Button
                className="homepage-search__search-button am_homepage_search"
                onClick={() => {
                  this.handleSearchClick(this.state.value);
                }}
              >
                Search
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => ({
  searchResults: state.homepageSearch.searchResults,
  isLoading: state.homepageSearch && state.homepageSearch.isFetching
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      homepageSearch
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(HomepageSearch): any);
