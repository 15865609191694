//@flow
import React from 'react';
import type {Element} from "React";

function PhoneLink(): Element<"a"> {
  return (
    <a href="tel:1800 633 813" className="am_phone_click">
      1800 633 813
    </a>
  );
}

function EmailLink(): Element<"a"> {
  return (
    <a href="mailto:enquiries@roymorgan.com" className="am_email_click">
      enquiries@roymorgan.com
    </a>
  );
}

const Contact = { PhoneLink, EmailLink } 

export default Contact;
