// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../middleware';
// import type { User } from '../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';

function updateUserRequest() {
  return {
    type: USER_UPDATE_REQUEST
  };
}

function updateUserSuccess(user: Object) {
  return {
    type: USER_UPDATE_SUCCESS,
    payload: user
  };
}

function updateUserFailure(error: string) {
  return {
    type: USER_UPDATE_FAILURE,
    error: error
  };
}

export const updateUser = (body: Object): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(updateUserRequest());
    const today = new Date();

    const requestBody = {
      ...body,
      dateUpdated: today.toISOString()
    };

    const response = await callAPI({
      method: 'PUT',
      endpoint: `${getApiBaseURL('v2', 'management')}/active-user`,
      body: requestBody,
      isLiveAPICall: true,
      useToken: true
    });

    dispatch(updateUserSuccess(response));
    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(updateUserFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      console.error(error);
    }
    return error;
  }
};
