// @flow
import * as actions from '../actions/cart/checkout';
import type { CheckoutState } from '../types';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

const intialState: CheckoutState = {
  isFetching: false,
  orderResult: true,
  lastOrder: null,
  lastError: null,
  thanyouOrder: null
};

export const checkout = (
  state: CheckoutState = intialState,
  action: Object
): CheckoutState => {
  switch (action.type) {
    case actions.PROCESS_ORDER_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case actions.PROCESS_ORDER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        orderResult: true,
        lastError: null,
        lastOrder: {
          // $FlowFixMe
          ...action.payload.response
        }
      };
    case actions.PROCESS_ORDER_CLEAR_LAST:
      return {
        ...state,
        isFetching: false,
        lastOrder: null,
        thanyouOrder: state.lastOrder ? state.lastOrder.id : null,
        paymentmethod: state.lastOrder
          ? state.lastOrder.paymentMethodId
          : undefined
      };
    case actions.PROCESS_ORDER_FAILURE:
      return {
        ...state,
        isFetching: false,
        orderResult: false,
        lastOrder: null,
        // $FlowFixMe
        lastError: {
          ...action.error
        }
      };
    case LOG_OUT_SUCCESS:
    default:
      return state;
  }
};
