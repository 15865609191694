// @flow
import * as Sentry from '@sentry/browser';
import { callAPI } from '../../middleware';
import type { ThunkAction, Action, Dispatch } from '../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

/* Apply coupon */
export const APPLY_COUPON_REQUEST = 'APPLY_COUPON_REQUEST';
export const APPLY_COUPON_SUCCESS = 'APPLY_COUPON_SUCCESS';
export const APPLY_COUPON_FAILURE = 'APPLY_COUPON_FAILURE';

const applyCouponRequest = (): Action => {
  return {
    type: APPLY_COUPON_REQUEST
  };
};

const applyCouponSuccess = (response: Object): Action => {
  return {
    type: APPLY_COUPON_SUCCESS,
    payload: { response }
  };
};

const applyCouponFailure = (error: string): Action => {
  return { type: APPLY_COUPON_FAILURE, error };
};

export const applyCoupon = (payload: Object): ThunkAction => async (
  dispatch: Dispatch,
  getState
) => {
  try {
    dispatch(applyCouponRequest());

    const response = await callAPI({
      method: 'POST',
      endpoint: `${getApiBaseURL('v2', 'store')}/public/coupon/apply`,
      body: payload,
      isLiveAPICall: true,
      useLocalApi: true,
      useToken: false
    });

    if (response instanceof Error) {
      // throw response;
      dispatch(applyCouponFailure(response));
      return response;
    }

    dispatch(applyCouponSuccess(response));
    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(applyCouponFailure(error));
    return error;
  }
};
