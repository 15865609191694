// @flow
import * as Sentry from '@sentry/browser';
import { callAPI } from '../../middleware';

export const GET_PRODUCTS_OPTIONS_REQUEST = 'GET_PRODUCTS_OPTIONS_REQUEST';
export const GET_PRODUCTS_OPTIONS_SUCCESS = 'GET_PRODUCTS_OPTIONS_SUCCESS';
export const GET_PRODUCTS_OPTIONS_FAILURE = 'GET_PRODUCTS_OPTIONS_FAILURE';

function setProductsOptionsRequest() {
  return {
    type: GET_PRODUCTS_OPTIONS_REQUEST
  };
}

function setProductsOptionsSuccess(content: string) {
  return {
    type: GET_PRODUCTS_OPTIONS_SUCCESS,
    payload: content
  };
}

function setProductsOptionsFailure(error: string) {
  return {
    type: GET_PRODUCTS_OPTIONS_FAILURE,
    error: error
  };
}

export const setProductsOptions = (body: Object): any => async (
  dispatch,
  getState
) => {
  try {
    dispatch(setProductsOptionsRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `/online_store/products/`,
      isLiveAPICall: false,
      useToken: false
    });
    dispatch(setProductsOptionsSuccess(response));
    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(setProductsOptionsFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      console.error(error);
    }
    return error;
  }
};
