// @flow
import * as Sentry from '@sentry/browser';
import { callAPI } from '../../middleware';
import type { ThunkAction, Action, Dispatch } from '../../types';
import { getApiBaseURL } from 'roy-morgan-auth';

export const PROCESS_ORDER_REQUEST = 'PROCESS_ORDER_REQUEST';
export const PROCESS_ORDER_SUCCESS = 'PROCESS_ORDER_SUCCESS';
export const PROCESS_ORDER_FAILURE = 'PROCESS_ORDER_FAILURE';
export const PROCESS_ORDER_CLEAR_LAST = 'PROCESS_ORDER_CLEAR_LAST';

const processOrderRequest = (): Action => {
  return {
    type: PROCESS_ORDER_REQUEST
  };
};

const processOrderSuccess = (response: Object): Action => {
  return {
    type: PROCESS_ORDER_SUCCESS,
    payload: {
      response
    }
  };
};

export const processOrderClearLast = (): Action => {
  return {
    type: PROCESS_ORDER_CLEAR_LAST
  };
};

const processOrderFailure = (error: string): Action => {
  return {
    type: PROCESS_ORDER_FAILURE,
    error
  };
};

export const processOrder = (
  payload: Object,
  selfRegisteredToken: string
): ThunkAction => async (dispatch: Dispatch, getState) => {
  try {
    dispatch(processOrderRequest());
    var endpoint = `/public/orders`;
    const response = await callAPI({
      method: 'POST',
      endpoint: `${getApiBaseURL('v2', 'store')}${endpoint}`,
      isLiveAPICall: true,
      useToken: true,
      useLocalApi: true,
      body: payload,
      selfRegisteredToken: selfRegisteredToken
    });

    if (response instanceof Error) {
      // throw response;
      dispatch(processOrderFailure(response));
      return response;
    }
    //  if the payment method is EFT, then return
    if (response.paymentMethodId === 2) {
      dispatch(processOrderSuccess(response));
    } else {
      var paymentStatusMessage =
        response &&
        response.paymentStatusMessage &&
        JSON.parse(response.paymentStatusMessage);
      if (paymentStatusMessage.message) {
        dispatch(processOrderFailure(paymentStatusMessage.message));
        return response;
      } else {
        dispatch(processOrderSuccess(response));
      }
    }
    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(processOrderFailure(error));
    return error;
  }
};
