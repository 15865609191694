// @flow
import * as React from 'react';
import { Header, Icon, Button } from 'semantic-ui-react';
import LoadSVG from './LoadSVG';
import ProductInfoFields from './ProductInfoFields';
import type { Product } from '../types';

type Props = {
  product: Product,
  itemExistsInCart: boolean,
  addToCart: (product: Product) => null,
};

class RelatedProduct extends React.Component<Props> {
  handleAddToCart: (event: any, product: Product) => void = (
    event: Object,
    product: Product
  ) => {
    let cartLeftOffset = document
      .getElementsByClassName('online-store__cart-header')[0]
      .getBoundingClientRect().left;

    let flyingImg = document.createElement('img');
    flyingImg.className = 'flying-image';
    flyingImg.src = product.iconLocation;
    flyingImg.style.position = 'fixed';
    flyingImg.style.width = '40px';
    flyingImg.style.transition = 'all 0.5s ease';
    flyingImg.style.zIndex = '10000';
    flyingImg.style.top =
      event.target.closest('.related-product').offsetTop -
      window.pageYOffset +
      90 +
      'px';
    flyingImg.style.left =
      event.target.closest('.related-product').getBoundingClientRect().left +
      10 +
      'px';

    let appendedFlyingImage = event.target
      .closest('body')
      .appendChild(flyingImg);
    setTimeout(function () {
      flyingImg.style.top = '0px';
      flyingImg.style.left = cartLeftOffset + 'px';
      flyingImg.style.opacity = '0';
    }, 0);
    setTimeout(function () {
      setTimeout(function () {
        document
          .getElementsByClassName('online-store__cart-header-icon')[0]
          .classList.add('shake');
      }, 0);
      setTimeout(function () {
        document
          .getElementsByClassName('online-store__cart-header-icon')[0]
          .classList.remove('shake');
      }, 500);
      appendedFlyingImage.parentNode.removeChild(appendedFlyingImage);
    }, 500);

    this.props.addToCart(product);
  };

  // TODO: (SG) replace this with a React Router Link
  productDetailClicked: (productSlug: string) => void = (
    productSlug: string
  ) => {
    window.location.href = `/product/${productSlug}`;
  };

  render(): React.Element<'div'> {
    const { product, itemExistsInCart } = this.props;
    return (
      <div width={12} className="related-product">
        <div className="related-product__header">
          <LoadSVG colour="#ffffff" src={product.iconLocation} />
          <Header as="h3">{product.name}</Header>
        </div>
        <div className="related-product__body">
          <ProductInfoFields
            country={product.countryName}
            price={product.price}
          />
          <div className="related-product__controls">
            <Button
              className="related-product__button"
              onClick={() => this.productDetailClicked(product.slug)}
              fluid
            >
              View Details
            </Button>
            {!itemExistsInCart ? (
              <Button
                className="related-product__button related-product__button--add-to-cart"
                onClick={(event) => this.handleAddToCart(event, product)}
                fluid
              >
                <i className="plus circle icon" />
                Add To Cart
              </Button>
            ) : (
              <span className="already-in-cart related-product__already-in-cart">
                <Icon name="left check" />
                Already In Cart
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default RelatedProduct;
