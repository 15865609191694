// @flow
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { formatCurrency } from '../../helpers';
import type { Element } from 'React';

type Props = {
  product: Object,
  addToCart: (e: any, product: Object) => void,
  itemExistsInCart: boolean,
};

function PriceAndControls({
  product,
  addToCart,
  itemExistsInCart,
}: Props): Element<'div'> {
  return (
    <div className="price-and-controls">
      <span className="product__price">
        {product &&
          product.price &&
          formatCurrency(product.price, undefined, undefined, 0)}
      </span>
      <span>
        {!itemExistsInCart ? (
          <Button
            className="product__add-to-cart"
            onClick={(event) => addToCart(event, product)}
            fluid
            compact
            size="medium"
          >
            <Icon name="plus circle" />
            Add To Cart
          </Button>
        ) : (
          <span className="already-in-cart">
            <Icon name="left check" />
            Already In Cart
          </span>
        )}
      </span>
    </div>
  );
}

export default PriceAndControls;
