export function persistCart(store) {
  const state = store.getState();

  let royMorganStorage = {};

  if (localStorage && localStorage.getItem('royMorganStorage')) {
    royMorganStorage = JSON.parse(
      localStorage.getItem('royMorganStorage') || '{}'
    );
  }

  // login or logged in user reload
  if (
    state.lastAction === 'LOG_IN_PROCESS_SUCCESS' ||
    state.lastAction === 'READ_USER_DETAILS_SUCCESS'
  ) {
    let cart = [];

    if (
      state.lastAction === 'LOG_IN_PROCESS_SUCCESS' &&
      royMorganStorage['loggedout'] &&
      royMorganStorage['loggedout'].length
    ) {
      cart = royMorganStorage['loggedout'];
    } else if (
      state.auth &&
      state.auth.user &&
      state.auth.user.user &&
      state.auth.user.user.id &&
      royMorganStorage[state.auth.user.user.id]
    ) {
      cart = royMorganStorage[state.auth.user.user.id];
    }

    store.dispatch({
      type: 'SET_CART',
      payload: cart
    });
  }

  // regular update actions
  if (
    state.lastAction === 'ADD_TO_CART_SUCCESS' ||
    state.lastAction === 'REMOVE_FROM_CART_SUCCESS' ||
    state.lastAction === 'SET_CART' ||
    state.lastAction === 'LOG_OUT_SUCCESS'
  ) {
    let userId = undefined;

    if (
      state.auth &&
      state.auth.user &&
      state.auth.user.user &&
      state.auth.user.user.id
    ) {
      userId = state.auth.user.user.id;
    }

    if (userId && state.cart && state.cart.cartItems) {
      royMorganStorage[userId] = state.cart.cartItems;
    } else {
      royMorganStorage['loggedout'] = state.cart.cartItems;
    }

    localStorage.setItem('royMorganStorage', JSON.stringify(royMorganStorage));
  }
}
