//@flow
import React from 'react';
import { Table } from 'semantic-ui-react';
import type { Order } from '../types';
import { Link } from 'react-router-dom';
import type {Element} from "React";

type Props = {
  orders: Array<Order>
};

class MyOrders extends React.Component<Props> {
  render(): Element<"div"> {
    // Create some mockup data for our tables
    const { orders } = this.props;

    const headerData = [
      'Order #',
      'Purchased On',
      'Payment Method',
      'Status',
      'Total'
    ];

    return (
      <div className="styled-datatable orders-listing">
        <h2>Previous Orders</h2>
        <Table striped celled>
          <Table.Header>
            <Table.Row>
              {headerData &&
                headerData.map((header, index) => (
                  <Table.HeaderCell key={index}>{header}</Table.HeaderCell>
                ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {orders &&
              orders.map((order, rowIndex) => (
                <Table.Row
                  key={rowIndex}
                  className={rowIndex % 2 === 0 ? 'grey' : ''}
                >
                  <Table.Cell>
                    <Link to={'/my-account/orders/' + order.id}>
                      {order.id}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={'/my-account/orders/' + order.id}>
                      {order.orderDate &&
                        new Intl.DateTimeFormat('en-AU', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                          hour12: true,
                          formatMatcher: 'best fit'
                        })
                          .format(new Date(order.orderDate))
                          .replace(',', '')}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={'/my-account/orders/' + order.id}>
                      {order.paymentMethod}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={'/my-account/orders/' + order.id}>
                      {order.paymentStatus}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={'/my-account/orders/' + order.id}>
                      ${order.totalAmount.toFixed(2)}
                    </Link>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default MyOrders;
