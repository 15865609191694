// @flow

import { changeUserTeam, loadUserProducts } from 'roy-morgan-auth';
import * as Sentry from '@sentry/browser';
import type { Dispatch, State } from '../types';

export const teamChange = (teamId: number): any => async (
  dispatch: Dispatch,
  getState: State
) => {
  try {
    await dispatch(changeUserTeam(teamId));
    await dispatch(loadUserProducts());
  } catch (error) {
    Sentry.captureException(error);
    return false;
  }
};
