// @flow
import * as Sentry from '@sentry/browser';
import { callAPI } from '../../middleware';

export const SET_HOMEPAGE_CONTENT_REQUEST = 'SET_HOMEPAGE_CONTENT_REQUEST';
export const SET_HOMEPAGE_CONTENT_SUCCESS = 'SET_HOMEPAGE_CONTENT_SUCCESS';
export const SET_HOMEPAGE_CONTENT_FAILURE = 'SET_HOMEPAGE_CONTENT_FAILURE';

function setHomepageContentRequest() {
  return {
    type: SET_HOMEPAGE_CONTENT_REQUEST
  };
}

function setHomepageContentSuccess(content: string) {
  return {
    type: SET_HOMEPAGE_CONTENT_SUCCESS,
    payload: content
  };
}

function setHomepageContentFailure(error: string) {
  return {
    type: SET_HOMEPAGE_CONTENT_FAILURE,
    error: error
  };
}

export const setHomepageContent = (body: Object): any => async (
  dispatch,
  getState
) => {
  try {
    dispatch(setHomepageContentRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `/online_store/homepage/`,
      isLiveAPICall: false,
      useToken: false
    });
    dispatch(setHomepageContentSuccess(response));
    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(setHomepageContentFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      console.error(error);
    }
    return error;
  }
};
