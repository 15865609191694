// @flow

import {
  changeUserOrganisation,
  loadUserTeams,
  loadUserProducts
} from 'roy-morgan-auth';
import * as Sentry from '@sentry/browser';
import type { Dispatch, State } from '../types';

export const organisationChange = (organisationId: number): any => async (
  dispatch: Dispatch,
  getState: () => State
) => {
  try {
    await dispatch(changeUserOrganisation(organisationId));
    await dispatch(loadUserTeams());
    await dispatch(loadUserProducts());
  } catch (error) {
    Sentry.captureException(error);
    return false;
  }
};
