// @flow
import * as Sentry from '@sentry/browser';
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE';

function getOrdersRequest() {
  return {
    type: GET_ORDERS_REQUEST
  };
}

function getOrdersSuccess(orders) {
  return {
    type: GET_ORDERS_SUCCESS,
    payload: orders
  };
}

function getOrdersFailure(error: string) {
  return {
    type: GET_ORDERS_FAILURE,
    error: error
  };
}

export const getOrders = (body: Object): any => async (dispatch, getState) => {
  try {
    refreshToken(dispatch);
    dispatch(getOrdersRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL('v2', 'store')}/public/orders`,
      isLiveAPICall: true,
      useToken: true
    });

    dispatch(getOrdersSuccess(response));
    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getOrdersFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      console.error(error);
    }
    return error;
  }
};
