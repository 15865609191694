// @flow
import { callAPI, refreshToken } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';
import * as Sentry from '@sentry/browser';

export const USER_UPDATE_PASSWORD_REQUEST = 'USER_UPDATE_PASSWORD_REQUEST';
export const USER_UPDATE_PASSWORD_SUCCESS = 'USER_UPDATE_PASSWORD_SUCCESS';
export const USER_UPDATE_PASSWORD_FAILURE = 'USER_UPDATE_PASSWORD_FAILURE';

function updateUserPasswordRequest() {
  return {
    type: USER_UPDATE_PASSWORD_REQUEST
  };
}

function updateUserPasswordSuccess(message: {}) {
  return {
    type: USER_UPDATE_PASSWORD_SUCCESS,
    payload: message
  };
}

function updateUserPasswordFailure(error: string) {
  return {
    type: USER_UPDATE_PASSWORD_FAILURE,
    error: error
  };
}

export const updateUserPassword = (body: Object): any => async (
  dispatch,
  getState
) => {
  try {
    refreshToken(dispatch);
    dispatch(updateUserPasswordRequest());
    const today = new Date();

    const requestBody = {
      ...body,
      dateUpdated: today.toISOString()
    };

    const response = await callAPI({
      method: 'POST',
      endpoint: `${getApiBaseURL(
        'v2',
        'management'
      )}/active-user/password/change`,
      body: requestBody,
      isLiveAPICall: true,
      useToken: true
    });

    dispatch(updateUserPasswordSuccess(response));
    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(updateUserPasswordFailure(error.message));
    /* istanbul ignore if */
    if (process.env.NODE_ENV !== 'test') {
      console.error(error);
    }
    return error;
  }
};
