// @flow
import * as React from 'react';
import RelatedProducts from './RelatedProducts';
import NewsletterSignup from './NewsletterSignup';
import type { Product } from '../types';

type Props = {
  product: Object,
  cartItems: Array<Product>,
  addToCart: () => any
};

class ProductSidebar extends React.PureComponent<Props> {
  render(): React.Element<"div"> {
    const { product, addToCart, cartItems } = this.props;
    return (
      <div className="product-sidebar">
        <h2 className="related-products__header">Related Products</h2>
        {product && cartItems && (
          <RelatedProducts
            product={product}
            addToCart={addToCart}
            cartItems={cartItems}
          />
        )}
        <NewsletterSignup />
      </div>
    );
  }
}

export default ProductSidebar;
