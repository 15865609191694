// @flow
import * as React from 'react';
import { Input, Button, Icon, Label } from 'semantic-ui-react';
import type { Dispatch } from '../types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { productSearch } from '../actions/pages/products.js';
import { Link } from 'react-router-dom';

type Props = {
  match: Object,
  location: Object,
  history: Object,
  isLoading: boolean,
  treeflat: Object,
  products: Array<Object>,
  productSearch: (
    category: ?string,
    query: string,
    reportType?: number,
    currentPage?: number
  ) => Promise<*>,
  searchedCategory: string,
  searchedKeyword: string,
  totalProducts: number,
  page: number,
  reportTypeId: number,
};

type State = {
  searchKeyword: string,
  reportType: number,
};

class ProductSearch extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchKeyword: props.searchedKeyword ? props.searchedKeyword : '',
      reportType: props.reportTypeId,
    };
  }

  componentDidUpdate(prevProps, preState) {
    if (this.props.searchedKeyword !== prevProps.searchedKeyword) {
      this.setState({
        searchKeyword: this.props.searchedKeyword,
      });
    }
    if (this.props.reportTypeId !== prevProps.reportTypeId) {
      this.setState({
        reportType: this.props.reportTypeId,
      });
    }
  }

  renderCategories = () => {
    const { treeflat } = this.props;
    const displayCategories = [];

    if (treeflat) {
      const chosenCategory =
        treeflat.entities.categories[this.props.searchedCategory];

      const findParent = (categoryItem, categoryList) => {
        categoryList.push(categoryItem);
        if (categoryItem.parentId > 0) {
          const foundCategory =
            treeflat.entities.categories[categoryItem.parentId];
          return findParent(foundCategory, categoryList);
        } else {
          return categoryList;
        }
      };

      if (chosenCategory) {
        if (chosenCategory.parentId > 0) {
          findParent(chosenCategory, displayCategories);
        } else {
          displayCategories.push(chosenCategory);
        }
      }
    }

    return displayCategories;
  };

  // Standard search: Search with set keyword in the current category
  searchKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.searchProducts();
    }
  };

  // Standard search: Search with set keyword in the current category
  searchButtonClicked = (event, { value }) => {
    this.searchProducts();
  };

  // Search with the current category, but reset the keyword
  clearKeyword = (event) => {
    this.setState({ searchKeyword: '' });
    this.props.productSearch(
      this.props.searchedCategory,
      '',
      this.props.reportTypeId,
      1
    );
  };

  // Reset the searched category, but still search with the keyword.
  clearCategory = (event) => {
    this.props.productSearch(
      null,
      this.state.searchKeyword,
      this.props.reportTypeId,
      1
    );
  };

  searchProducts = () => {
    this.props.productSearch(
      this.props.searchedCategory,
      this.state.searchKeyword,
      this.state.reportType
    );
    this.props.history.push(
      this.props.match.url + `?keyword=${this.state.searchKeyword}`
    );
  };

  searchInputChanged = (event, { value }) => {
    value !== undefined && this.setState({ searchKeyword: value });
  };

  render() {
    const {
      products,
      isLoading,
      searchedKeyword,
      match,
      totalProducts,
      page,
    } = this.props;
    const { searchKeyword } = this.state;
    const filters = this.renderCategories();

    const FilterLabel = (props: { category: { title: string } }) => (
      <Label className="product-search__filter-tag">
        {props.category.title}
        <Link
          to={
            this.props.searchedKeyword
              ? `/products?keyword=${searchedKeyword}`
              : '/products'
          }
          onClick={this.clearCategory.bind(this)}
        >
          <Icon name="delete" />
        </Link>
      </Label>
    );
    return (
      <div className="product-search">
        <div className="product-search__search-box">
          <Input
            loading={isLoading}
            onChange={this.searchInputChanged}
            onKeyPress={this.searchKeyDown.bind(this)}
            value={searchKeyword}
            placeholder="Search..."
            className="product-search__search-input"
          />
          <Button
            className="product-search__search-button am_product_search"
            onClick={this.searchButtonClicked.bind(this)}
          >
            Search
          </Button>
        </div>
        <div className="product-search__search-filter-wrapper">
          <span className="product-search__result-count">
            <strong>
              {products && products.length
                ? (page - 1) * 12 +
                  1 +
                  ' - ' +
                  ((page - 1) * 12 + products.length)
                : '0'}
            </strong>{' '}
            of
            <strong>
              {' '}
              {totalProducts && products && products.length
                ? totalProducts
                : '0'}
            </strong>{' '}
            results
          </span>
          <div className="product-search__filter-tag-wrapper">
            {filters && filters.length > 0 && (
              <FilterLabel
                category={filters.reverse().pop()}
                searchedKeyword={searchedKeyword}
              />
            )}

            {searchedKeyword && searchedKeyword.length && (
              <Label className="product-search__filter-tag">
                Keyword: {searchedKeyword}
                <Link to={match.url} onClick={this.clearKeyword.bind(this)}>
                  <Icon name="delete" />
                </Link>
              </Label>
            )}
          </div>
        </div>
      </div>
    );
  }
}

// export default ProductSearch;

const mapStateToProps = function (state) {
  return {
    products: state.productList.products,
    isLoading: state.productList.isFetching,
    searchedCategory: state.productList.searchedCategory,
    searchedKeyword: state.productList.searchedKeyword,
    treeflat: state.treenav.treeflat,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      productSearch,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSearch): any);
