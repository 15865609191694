// @flow
import * as React from 'react';
import { getProduct } from '../actions/pages/products';
import { addToCart } from '../actions/cart/addToCart';
import { setProductsOptions } from '../actions/pages/cmsProductsOptions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import type { Dispatch, Product } from '../types';
import LoadingIndicator from '../components/LoadingIndicator';
import * as Sentry from '@sentry/browser';
import ReactHTMLParser from 'html-react-parser';
import ProductSidebar from '../components/ProductSidebar';
import HeaderItems from '../components/Product/HeaderItems';
import { Container, Grid, Header, Image, Icon } from 'semantic-ui-react';

type Props = {
  isFetching: boolean,
  product: Object,
  match: {
    params: {
      productId: string,
    },
  },
  promoBanner: string,
  promoBannerLink: string,
  cartItems: Array<Product>,
  getProduct: (id: string) => Promise<*>,
  setProductsOptions: () => Promise<*>,
  addToCart: (product: Object) => Promise<*>,
};

let fileFormats = {
  '1': 'pdf',
  '2': 'powerpoint',
  '3': 'video',
  '4': 'archive',
  '5': 'excel',
};

class Products extends React.Component<Props> {
  componentDidMount() {
    const { productId } = this.props.match.params;
    this.props.getProduct(productId);
    this.props.setProductsOptions();
  }

  addToCart = (event, product) => {
    this.props.addToCart(product);

    var cartTopOffset = document
        .getElementsByClassName('online-store__cart-header')[0]
        .getBoundingClientRect().top,
      cartLeftOffset = document
        .getElementsByClassName('online-store__cart-header')[0]
        .getBoundingClientRect().left;

    var flyingImg = document.createElement('i');
    flyingImg.className = 'flying-image left plus circle icon';
    flyingImg.style.position = 'absolute';
    flyingImg.style.transition = 'all 0.5s ease';
    flyingImg.style.color = '#00a3c7';
    flyingImg.style.zIndex = '10000';
    flyingImg.style.top =
      event.target.closest('.product__add-to-cart').getBoundingClientRect()
        .top + 'px';
    flyingImg.style.left =
      event.target.closest('.product__add-to-cart').getBoundingClientRect()
        .left + 'px';

    var appendedFlyingImage = event.target
      .closest('body')
      .appendChild(flyingImg);
    setTimeout(function () {
      flyingImg.style.top = cartTopOffset + 'px';
      flyingImg.style.left = cartLeftOffset + 'px';
      flyingImg.style.width = '40px';
      flyingImg.style.opacity = '0';
    }, 0);
    setTimeout(function () {
      setTimeout(function () {
        document
          .getElementsByClassName('online-store__cart-header-icon')[0]
          .classList.add('shake');
      }, 0);
      setTimeout(function () {
        document
          .getElementsByClassName('online-store__cart-header-icon')[0]
          .classList.remove('shake');
      }, 500);
      appendedFlyingImage.parentNode.removeChild(appendedFlyingImage);
    }, 500);
  };

  /**
   * If the SKU number is not 7 digits, prepend 0's until
   * it is actually 7 digits.
   */
  formatSku = (sku: string) => {
    const totalNeeded = 7;
    if (sku) {
      return sku.toString().padStart(totalNeeded, '0');
    } else {
      Sentry.captureMessage('Product.js: Undefined SKU');
    }
  };

  render() {
    const {
      isFetching,
      product,
      addToCart,
      promoBanner,
      promoBannerLink,
      cartItems,
    } = this.props;
    if (isFetching && !product) {
      return <LoadingIndicator />;
    }

    if (!product) {
      return (
        <div>
          <Container>
            <Grid stackable className="page-container">
              <Grid.Row>
                <Grid.Column>
                  <span className="product__not-found">Product not found</span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </div>
      );
    } else {
      const itemExistsInCart =
        cartItems &&
        product &&
        cartItems.some(function (el) {
          return el.id === product.id;
        });
      return (
        <div>
          <Container>
            <Grid stackable className="page-container">
              <Grid.Row>
                <Grid.Column mobile={12} tablet={9} computer={9}>
                  <Grid stackable>
                    <Grid.Row>
                      <Grid.Column mobile={12} tablet={12} computer={6}>
                        <Header as="h2" className="product__title">
                          {product.name}
                        </Header>
                        <div className="product__extra-info">
                          <span>ID #{this.formatSku(product.id)}</span>
                          <span>
                            Country: {product.country ? product.country : 'N/A'}
                          </span>
                          <span>
                            Format:{' '}
                            {fileFormats[product.itemFormatId].toUpperCase()}{' '}
                            &nbsp;
                            <Icon
                              className={`file icon outline product-icons__icon--file ${
                                fileFormats[product.itemFormatId]
                              }`}
                              name=""
                            />
                          </span>
                        </div>
                      </Grid.Column>
                      <Grid.Column mobile={12} tablet={12} computer={6}>
                        <HeaderItems
                          product={product}
                          addToCart={this.addToCart}
                          itemExistsInCart={itemExistsInCart}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    {/*start product content*/}
                    <Grid.Row>
                      <Grid.Column width={12} className="product-content">
                        {ReactHTMLParser(product.fulldesc1 || '')}
                      </Grid.Column>
                      <Grid.Column
                        width={product.fulldesc3 ? 6 : 12}
                        className="product-content"
                      >
                        {ReactHTMLParser(product.fulldesc2 || '')}
                      </Grid.Column>
                      <Grid.Column
                        width={product.fulldesc2 ? 6 : 12}
                        className="product-content"
                      >
                        {ReactHTMLParser(product.fulldesc3 || '')}
                      </Grid.Column>
                      {product.fulldesc2 || product.fulldesc3 ? null : ''}
                      <Grid.Column width={12} className="product-content">
                        {ReactHTMLParser(product.fulldesc4 || '')}
                      </Grid.Column>
                      <Grid.Column width={12} className="product-content">
                        {product.timePeriod &&
                        product.sampleSize !== undefined &&
                        product.sampleSize !== 0 ? (
                          <p>
                            <strong>
                              Sample size for {product.timePeriod} report is{' '}
                              {product.sampleSize}
                            </strong>
                          </p>
                        ) : (
                          ''
                        )}
                      </Grid.Column>
                      <Grid.Column width={12} className="product-content">
                        {product.turnAroundTime !== undefined ? (
                          <p>
                            {product.turnAroundTime === 0 ? (
                              <strong>
                                Product available as immediate download.
                              </strong>
                            ) : (
                              <strong>
                                Product delivered within{' '}
                                {product.turnAroundTime} working day
                                {product.turnAroundTime > 1 ? 's' : ''}.
                              </strong>
                            )}
                          </p>
                        ) : (
                          ''
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
                <Grid.Column
                  className="product__sidebar-column"
                  mobile={12}
                  tablet={3}
                  computer={3}
                >
                  <ProductSidebar
                    product={product}
                    cartItems={cartItems}
                    addToCart={addToCart}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
          {promoBanner && promoBannerLink && (
            <a href={promoBannerLink} target="_blank" rel="noopener noreferrer">
              <Image
                className="ui fluid image"
                alt="Promo Banner"
                src={promoBanner}
              />
            </a>
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state, props) => ({
  isFetching: state.productPage.isFetching,
  product: state.productPage.product,
  promoBanner:
    state.cmsProductsOptions &&
    state.cmsProductsOptions.singleProductPromoBanner,
  promoBannerLink:
    state.cmsProductsOptions &&
    state.cmsProductsOptions.singleProductPromoBannerLink,
  cartItems: state && state.cart && state.cart.cartItems,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getProduct,
      addToCart,
      setProductsOptions,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(Products): any);
