// @flow
import React from 'react';
import { Grid, Form, Button, Checkbox, Dropdown } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { getUserData } from 'roy-morgan-auth';
import { connect } from 'react-redux';
import { removeFromCart } from '../actions/cart/removeFromCart';
import ShoppingCart from '../components/ShoppingCart';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe
} from 'react-stripe-elements';
import { selfRegister } from '../actions/user/selfRegister';
import { updateUser } from '../actions/user/updateUser';
import { Link } from 'react-router-dom';
import type { Dispatch } from '../types';

type Order = {
  name?: string,
  company?: string,
  email?: string,
  phone?: string,
  address_one?: string,
  address_two?: string,
  password?: string,
  address_one?: string,
  address_two?: string,
  suburb?: string,
  state?: string,
  postcode?: string,
  signup_to_newsletter?: boolean,
  email_confirm?: string,
  payment_type?: string,
  password?: string,
  password_confirm?: string,
  country?: string
};

type Props = {
  history: Object,
  stripe: Object,
  order: Order,
  handleFormSubmit: (formData: Order, token?: string) => Promise<Object>,
  cartItems: any,
  removeFromCart: () => Promise<*>,
  formData: Object,
  selfRegister: (formData: Order) => Promise<Object>,
  updateUser: (userData: Object) => Promise<Object>,
  getUserData: () => Promise<*>,
  user: Object,
  company: Object,
  isFetching: boolean,
  couponValid: boolean,
  couponCode: String
};

type State = {
  formData: Order,
  checkPhoneExists: boolean,
  countries: Object,
  errorMessage: ?string,
  errorMessage: string,
  errorStatus: boolean
};

class CheckoutFormAndDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const countryList = require('country-list');
    const countries = countryList.getData();

    // $FlowFixMe
    this.handleInputChange = this.handleInputChange.bind(this);
    // $FlowFixMe
    this.handleMobileInputChange = this.handleMobileInputChange.bind(this);
    // $FlowFixMe
    this.submitForm = this.submitForm.bind(this);
    let name = '';
    if (this.props.order && this.props.order.name) {
      name = this.props.order.name;
    } else if (this.props.user && this.props.user.name) {
      name = this.props.user.name;
    }
    let email = '';
    if (this.props.order && this.props.order.email) {
      email = this.props.order.email;
    } else if (this.props.user && this.props.user.email) {
      email = this.props.user.email;
    }
    let phone = '';
    if (this.props.order && this.props.order.phone) {
      phone = this.props.order.phone;
    } else if (this.props.user && this.props.user.phone) {
      phone = this.props.user.phone;
    }

    let checkPhoneExists = true;
    if (!phone) {
      checkPhoneExists = false;
    }

    let company = '';
    if (this.props.order && this.props.order.company) {
      company = this.props.order.company;
    } else if (this.props.company && this.props.company.name) {
      company = this.props.company.name;
    }

    // Filter out name of self registration organisation
    let organisationTypeId = -1;
    if (this.props.company && this.props.company.organisationTypeId) {
      organisationTypeId = this.props.company.organisationTypeId;
    }

    if (organisationTypeId === 3) {
      company = '';
    }
    // End: Filter out name of self registration organisation

    this.state = {
      formData: {
        name: name,
        email: email,
        email_confirm: email,
        phone: phone,
        company: company,
        signup_to_newsletter: true,
        payment_type: '1',
        country: 'Australia',
        address_one: '',
        suburb: '',
        state: '',
        postcode: ''
      },
      countries: countries,
      checkPhoneExists: checkPhoneExists,
      errorMessage: '',
      errorStatus: false
    };
  }

  togglePaymentMethod = (event, value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        payment_type: value.value
      }
    });
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value
      }
    });
  }

  handleMobileInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (event.target.value.match('^[+0-9 ]*$') !== null) {
      this.setState({
        formData: {
          ...this.state.formData,
          [name]: value
        }
      });
    }
  }

  handleCountryChange(event, data) {
    const value = data.value;
    const name = 'country';

    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value
      }
    });
  }

  async submitForm(ev) {
    ev.preventDefault();

    this.setState({
      errorStatus: false,
      errorMessage: ''
    });

    const { checkPhoneExists, formData } = this.state;
    const {
      name,
      phone,
      address_one,
      address_two,
      company,
      email,
      email_confirm,
      suburb,
      state,
      postcode,
      password,
      password_confirm,
      country,
      signup_to_newsletter,
      payment_type
    } = formData;

    const {
      handleFormSubmit,
      getUserData,
      user,
      selfRegister,
      couponValid,
      couponCode
    } = this.props;

    if (email !== email_confirm) {
      this.setState({
        errorStatus: true,
        errorMessage: "Email and Confirm Email doesn't match!"
      });
    } else if (password !== password_confirm) {
      this.setState({
        errorStatus: true,
        errorMessage: "Password and Confirm Password doesn't match!"
      });
    } else {
      let storeItems = [];
      for (let key in this.props.cartItems) {
        storeItems.push({ storeItemId: this.props.cartItems[key].id });
      }

      let combinedAddress = address_one ? address_one : '';
      combinedAddress = address_two
        ? combinedAddress + ' ' + address_two
        : combinedAddress;
      let updateUserResult = '';
      // payment type for EFT = 2
      const currentPostcode = postcode ? postcode : '';

      if (payment_type === '2') {
        const payload = {
          billingAddress:
            combinedAddress +
            ' ' +
            // $FlowFixMe
            suburb +
            ' ' +
            // $FlowFixMe
            state +
            ' ' +
            currentPostcode,
          couponCode: couponValid ? couponCode : null,
          name: name,
          phone: phone,
          email: email,
          orgName: company,
          country: country,
          paymentMethodId: parseInt(payment_type, 10),
          items: storeItems,
          password: password,
          signup_to_newsletter: signup_to_newsletter
        };
        if (user) {
          // check if the logged in customer have phone number or not. If not then fill that, else submit form
          if (!checkPhoneExists) {
            updateUserResult = this.props.updateUser({ phone: phone });
            // update the phone number if not there.
            updateUserResult.then((results: Object) => {
              if (results.code !== 'API') {
                handleFormSubmit(payload);
              } else {
                // $FlowFixMe
                this.setState({ errorMessage: updateUserResult.message });
              }
            });
          } else {
            handleFormSubmit(payload);
          }
        } else {
          selfRegister(payload).then(results => {
            if (results.code !== 'API') {
              getUserData().then(success => {
                handleFormSubmit(payload, results.token);
              });
            } else {
              this.setState({ errorMessage: results.message });
            }
          });
        }
      } else {
        //stripe
        const { token } = await this.props.stripe.createToken({ name });

        let stripeElements = document.getElementsByClassName('StripeElement');

        for (let i = 0; i < stripeElements.length; i++) {
          if (stripeElements[i].classList.contains('StripeElement--empty')) {
            stripeElements[i].style.border = '1px solid red';
          } else {
            stripeElements[i].style.border = '1px solid #aaa';
          }
        }

        const currentSuburb = suburb ? suburb : '';
        const currentPostcode = postcode ? postcode : '';

        if (token && token.id) {
          const payload = {
            billingAddress:
              combinedAddress +
              ' ' +
              currentSuburb +
              ' ' +
              // $FlowFixMe
              state +
              ' ' +
              currentPostcode,
            couponCode: couponValid ? couponCode : null,
            name: name,
            phone: phone,
            email: email,
            orgName: company,
            country: country,
            token: token && token.id,
            paymentMethodId: parseInt(payment_type, 10),
            items: storeItems,
            password: password,
            signup_to_newsletter: signup_to_newsletter
          };
          if (user) {
            // check if the logged in customer have phone number or not. If not then fill that, else submit form
            if (!checkPhoneExists) {
              updateUserResult = this.props.updateUser({ phone: phone });
              // update the phone number if not there.
              updateUserResult.then(results => {
                if (results.code !== 'API') {
                  handleFormSubmit(payload);
                } else {
                  // $FlowFixMe
                  this.setState({ errorMessage: updateUserResult.message });
                }
              });
            } else {
              handleFormSubmit(payload);
            }
          } else {
            selfRegister(payload).then(results => {
              if (results.code !== 'API') {
                getUserData().then(success => {
                  handleFormSubmit(payload, results.token);
                });
              } else {
                this.setState({ errorMessage: results.message });
              }
            });
          }
        }
      }
    }
  }

  onCloseMessage = () => {
    this.setState({
      errorStatus: false,
      errorMessage: ''
    });
  };

  render() {
    const { user, isFetching } = this.props;
    const {
      checkPhoneExists,
      formData,
      errorStatus,
      errorMessage
    } = this.state;
    const {
      name,
      company,
      email,
      email_confirm,
      address_one,
      address_two,
      phone,
      password,
      password_confirm,
      suburb,
      state,
      postcode,
      country,
      signup_to_newsletter
    } = formData;

    let style = {
      base: {
        // Add your base input styles here. For example:
        fontFamily: '"Proxima Nova", sans-serif'
      }
    };

    const countryList = require('country-list');
    const countries = countryList.getData();
    const formattedCountries = countries.map(country => ({
      key: country.code,
      value: country.name,
      text: country.name
    }));

    // By default selected country is Australia
    const selectedCountry = country ? country : 'Australia';

    return (
      <div className="checkout-form-and-details">
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={12} tablet={12} computer={12}>
              {errorStatus && (
                <div className="ui error message message-transition-enter-done checkout-form-and-details__invalid">
                  <i
                    aria-hidden="true"
                    className="close icon"
                    onClick={this.onCloseMessage.bind(this)}
                  />
                  <div className="content">
                    <p>{errorMessage}</p>
                  </div>
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid>
          {!user && (
            <div className="checkout-form-and-details__already-member">
              <span>Already a member? Then&nbsp;</span>
              <Link to={'/login'} className="am_checkout_login">
                log in here
              </Link>
            </div>
          )}
          <Grid.Row className="checkout-form-and-details__outer-row">
            <Grid.Column mobile={12} tablet={8} computer={8}>
              <Form
                onSubmit={this.submitForm.bind(this)}
                className="checkout-form-and-details__checkout-form"
              >
                <Grid>
                  <Grid.Row>
                    <Grid.Column mobile={12} tablet={6} computer={6}>
                      <Form.Input
                        placeholder="Name"
                        name="name"
                        type="text"
                        value={name}
                        required
                        onChange={this.handleInputChange}
                        readOnly={user}
                        className={user ? 'checkout-form__read-only' : ''}
                      />
                      <Form.Input
                        placeholder="Phone"
                        name="phone"
                        type="phone"
                        value={phone}
                        required
                        maxLength="15"
                        onChange={this.handleMobileInputChange}
                        readOnly={user && phone && checkPhoneExists}
                        className={
                          user && phone && checkPhoneExists
                            ? 'checkout-form__read-only'
                            : ''
                        }
                      />
                      <Form.Input
                        placeholder="Email"
                        name="email"
                        type="email"
                        value={email}
                        required
                        onChange={this.handleInputChange}
                        readOnly={user}
                        className={user ? 'checkout-form__read-only' : ''}
                      />
                      {!user && (
                        <React.Fragment>
                          <Form.Input
                            placeholder="Confirm Email"
                            name="email_confirm"
                            type="email"
                            required
                            value={email_confirm}
                            onChange={this.handleInputChange}
                          />
                          <Form.Input
                            placeholder="Password"
                            name="password"
                            type="password"
                            value={password}
                            required
                            onChange={this.handleInputChange}
                          />
                          <Form.Input
                            placeholder="Confirm Password"
                            name="password_confirm"
                            type="password"
                            required
                            value={password_confirm}
                            onChange={this.handleInputChange}
                          />
                        </React.Fragment>
                      )}
                      <Form.Input
                        label="Signup to Newsletter"
                        name="signup_to_newsletter"
                        type="checkbox"
                        checked={signup_to_newsletter}
                        onChange={this.handleInputChange}
                        className="checkout-checkbox"
                      />
                    </Grid.Column>
                    <Grid.Column mobile={12} tablet={6} computer={6}>
                      <Form.Input
                        placeholder="Company"
                        name="company"
                        type="text"
                        value={company}
                        onChange={this.handleInputChange}
                      />
                      <Form.Input
                        placeholder="Address #1"
                        name="address_one"
                        type="text"
                        required
                        value={address_one}
                        onChange={this.handleInputChange}
                      />
                      <Form.Input
                        placeholder="Address #2 (optional)"
                        name="address_two"
                        value={address_two}
                        onChange={this.handleInputChange}
                        type="text"
                      />
                      <Form.Input
                        placeholder="Suburb"
                        name="suburb"
                        required
                        type="text"
                        value={suburb}
                        onChange={this.handleInputChange}
                      />
                      <Form.Input
                        placeholder="State"
                        name="state"
                        required
                        type="text"
                        value={state}
                        onChange={this.handleInputChange}
                      />
                      {this.state && this.state.countries && (
                        <Dropdown
                          placeholder="Select Country..."
                          fluid
                          selection
                          search
                          className="checkout-form-and-details__dropdown"
                          name="country"
                          value={selectedCountry}
                          options={formattedCountries}
                          onChange={this.handleCountryChange.bind(this)}
                        />
                      )}

                      <Form.Input
                        placeholder="Post Code"
                        name="postcode"
                        required
                        type="text"
                        value={postcode}
                        onChange={this.handleInputChange}
                      />
                      <div className="payment-fields">
                        <h2>Payment Details</h2>
                        <Form.Field>
                          <Checkbox
                            radio
                            label="Credit Card"
                            name="payment_type"
                            value="1"
                            checked={this.state.formData.payment_type === '1'}
                            onChange={this.togglePaymentMethod}
                          />
                          <Checkbox
                            radio
                            label="EFT"
                            name="payment_type"
                            value="2"
                            checked={this.state.formData.payment_type === '2'}
                            onChange={this.togglePaymentMethod}
                          />
                        </Form.Field>
                        {this.state &&
                        this.state.formData.payment_type === '2' ? (
                          <div className="eft-fields">
                            EFT Transfer details will be provide on the next
                            page after the order is submitted.
                          </div>
                        ) : (
                          <div className="credit-card-fields">
                            <CardNumberElement
                              className="osCardNumber"
                              style={style}
                            />
                            <CardExpiryElement style={style} required />
                            <CardCVCElement style={style} required />
                          </div>
                        )}
                      </div>

                      <Button
                        className="organisation-form__submit am_submit_order"
                        type="submit"
                        loading={isFetching}
                        disabled={isFetching}
                      >
                        Submit Order
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Grid.Column>
            <Grid.Column mobile={12} tablet={4} computer={4}>
              <div className="checkout-cart" style={{ marginBottom: '10px' }}>
                <ShoppingCart
                  showHeading={true}
                  showTotal={true}
                  selectedCountry={selectedCountry}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  cartItems: state.cart.cartItems,
  couponCode: state.cart.couponCode,
  couponValid: state.cart.couponValid,
  user: state.auth.user.user
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      removeFromCart,
      selfRegister,
      updateUser,
      getUserData
    },
    dispatch
  );

export default (injectStripe(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CheckoutFormAndDetails)
): any);
