//@flow
import React from 'react';
import type {Element} from "React";

type Props = {
  label: ?string,
  value: ?string
};

class StaticField extends React.Component<Props> {
  render(): Element<"div"> {
    const { label, value } = this.props;
    return (
      <div className="field">
        <div className="field__non-interactive-label">{label}</div>
        <div className="field__non-interactive-input" name="verifiedEmail">
          {value}
        </div>
      </div>
    );
  }
}

export default StaticField;
