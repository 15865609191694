//@flow
import React from 'react';
import { productRelatedSearch } from '../actions/pages/products.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import type { Product } from '../types';
import RelatedProduct from './RelatedProduct';

type Props = {
  isFetching: boolean,
  product: Object,
  relatedProducts: any,
  productRelatedSearch: (itemId: number) => Promise<*>,
  addToCart: Function,
  cartItems: Array<Product>
};

class RelatedProducts extends React.Component<Props> {
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.product) {
      this.props.productRelatedSearch(this.props.product.id);
    }
  }

  componentDidUpdate(prevProps, preState) {
    window.scrollTo(0, 0);
    if (this.props.product.id !== prevProps.product.id) {
      this.props.productRelatedSearch(this.props.product.id);
    }
  }

  render() {
    const { relatedProducts, product, cartItems, addToCart } = this.props;
    var noPaddingOrMargin = {
      margin: 0,
      padding: 0
    };
    var itemExistsInCart = false;
    return (
      <React.Fragment>
        <div style={noPaddingOrMargin} id="related-product__wrapper">
          {relatedProducts &&
            relatedProducts
              .filter(related => {
                if (product) {
                  return related.id !== product.id;
                } else {
                  return true;
                }
              })
              .slice(0, 2)
              .map((product, i) => {
                itemExistsInCart =
                  cartItems &&
                  product &&
                  cartItems.some(function(el) {
                    return el.id === product.id;
                  });
                return (
                  <RelatedProduct
                    key={i}
                    product={product}
                    itemExistsInCart={itemExistsInCart}
                    addToCart={addToCart}
                  />
                );
              })}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    isFetching: state.productList.isFetching,
    relatedProducts: state.productList.products
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      productRelatedSearch
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(RelatedProducts): any);
