// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import NestedNode from './TreeNode';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { productSearch } from '../actions/pages/products';
import type { Element } from 'React';

type Node = {
  id: number,
  title: string,
  slug: string,
  children?: Array<Node>,
};

type Props = {
  node: any,
  currentActivePage: number,
  setActivePage: Function,
  productSearch: (
    category: ?string,
    query: string,
    reportType?: number,
    currentPage?: number,
    clearSearch?: boolean
  ) => Promise<*>,
  searchedCategory: string,
  searchedKeyword: string,
  reportTypeId: number,
  pageLoadCheck: Object | null,
};

type State = {
  active: boolean,
};

class TreeNode extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // Check to see if this category exists in the route.
    // If it does, then it needs to be expanded and shown to the user.
    if (this.categoryExistsInRoute()) {
      this.state = {
        active: true,
      };
    } else {
      this.state = {
        active: false,
      };
    }
  }

  categoryExistsInRoute(): boolean {
    const { pageLoadCheck, node } = this.props;
    if (
      pageLoadCheck &&
      pageLoadCheck.find((category) => category.id === node.id)
    ) {
      return true;
    }
    return false;
  }

  toggleClass: (e: SyntheticEvent<EventTarget>) => void = (
    e: SyntheticEvent<*>
  ) => {
    e.stopPropagation();
    const currentState = this.state.active;
    this.setState({ active: !currentState });
    this.searchProducts();
  };

  linkClick: (e: SyntheticEvent<any>) => void = (e: SyntheticEvent<*>) => {
    // e.stopPropagation();
    this.searchProducts();
  };

  searchProducts: () => void = () => {
    if (this.props.currentActivePage !== this.props.node.id) {
      this.props.setActivePage(this.props.node.id);
      this.props.productSearch(
        this.props.node.id,
        this.props.searchedKeyword,
        // $FlowFixMe
        null,
        // $FlowFixMe
        null,
        true
      );
    }
  };

  render(): Element<'li'> {
    const {
      node,
      setActivePage,
      currentActivePage,
      pageLoadCheck,
      productSearch,
    } = this.props;
    const { active } = this.state;

    // If this node is the same one being searched, this will highlight it
    const isHighlighted = node.id === this.props.searchedCategory;

    // this is the URL that will actually be clicked by the user, with the query string
    // hot fix 07/06/2019 undefined urls cause puppeteer to make the build fail so replace them with blank url
    const nodeUrlWithQuery =
      `${node.slug}` !== 'undefined' ? `/products/${node.slug}` : `/products/#`;
    if (node.children && node.children.length >= 0) {
      return (
        <li
          className={
            active
              ? 'tree-nav__menu-parent'
              : 'tree-nav__menu-parent tree-nav__menu-parent--collapsed'
          }
        >
          <Link
            to={nodeUrlWithQuery}
            onClick={this.toggleClass.bind(this)}
            key={node.id}
          >
            <span className={isHighlighted ? 'active' : ''}>
              {node.title}
              <div className="caret" />
            </span>
          </Link>
          <ul className="tree-nav__menu">
            {node.children.map((item, i) => (
              <NestedNode
                node={item}
                key={i}
                currentActivePage={currentActivePage}
                setActivePage={setActivePage}
                pageLoadCheck={pageLoadCheck}
                productSearch={productSearch}
              />
            ))}
          </ul>
        </li>
      );
    } else {
      return (
        <li className={isHighlighted ? 'active' : ''}>
          <Link to={nodeUrlWithQuery} onClick={this.linkClick.bind(this)}>
            {node.title}
          </Link>
        </li>
      );
    }
  }
}
const mapStateToProps = function (state) {
  return {
    searchedCategory: state.productList.searchedCategory,
    searchedKeyword: state.productList.searchedKeyword,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ productSearch }, dispatch);

export default (connect(mapStateToProps, mapDispatchToProps)(TreeNode): any);
