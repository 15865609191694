// @flow
import * as Sentry from '@sentry/browser';
import { callAPI } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const SET_PRODUCT_LIST_REQUEST = 'SET_PRODUCT_LIST_REQUEST';
export const SET_PRODUCT_LIST_SUCCESS = 'SET_PRODUCT_LIST_SUCCESS';
export const SET_PRODUCT_LIST_FAILURE = 'SET_PRODUCT_LIST_FAILURE';
export const GET_TREE_NAV_REQUEST = 'GET_TREE_NAV_REQUEST';
export const GET_TREE_NAV_SUCCESS = 'GET_TREE_NAV_SUCCESS';
export const GET_TREE_NAV_FAILURE = 'GET_TREE_NAV_FAILURE';
export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';

function setProductListRequest() {
  return {
    type: SET_PRODUCT_LIST_REQUEST
  };
}

function setProductListSuccess(productList: string) {
  return {
    type: SET_PRODUCT_LIST_SUCCESS,
    payload: productList
  };
}

function setProductListFailure(error: string) {
  return {
    type: SET_PRODUCT_LIST_FAILURE,
    error: error
  };
}

// TODO: not sure if we are using this function anywhere, I found this while
// updating V1 API's to V2
export const setProductList = (body: Object): any => async (
  dispatch,
  getState
) => {
  try {
    dispatch(setProductListRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL('v2', 'store')}/public/items`,
      isLiveAPICall: true,
      useToken: true
    });
    dispatch(setProductListSuccess(response));
    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(setProductListFailure(error.message));
    return error;
  }
};

function getTreeNavRequest() {
  return {
    type: GET_TREE_NAV_REQUEST
  };
}

function getTreeNavSuccess(treenav: string) {
  return {
    type: GET_TREE_NAV_SUCCESS,
    payload: treenav
  };
}

function getTreeNavFailure(error: string) {
  return {
    type: GET_TREE_NAV_FAILURE,
    error: error
  };
}

export const getTreeNav = (body: Object): any => async (dispatch, getState) => {
  try {
    dispatch(getTreeNavRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL('v2', 'store')}/public/tree`,
      isLiveAPICall: true,
      useToken: true
    });
    dispatch(getTreeNavSuccess(response));
    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getTreeNavFailure(error.message));
    return error;
  }
};

function getProductRequest() {
  return {
    type: GET_PRODUCT_REQUEST
  };
}

function getProductSuccess(product: string) {
  return {
    type: GET_PRODUCT_SUCCESS,
    payload: product
  };
}

function getProductFailure(error: string) {
  return {
    type: GET_PRODUCT_FAILURE,
    error: error
  };
}

export const getProduct = (productSlug: string): any => async (
  dispatch,
  getState
) => {
  try {
    dispatch(getProductRequest());

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL('v2', 'store')}/public/item/${productSlug}`,
      isLiveAPICall: true,
      useToken: true
    });
    dispatch(getProductSuccess(response));
    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(getProductFailure(error.message));
    return error;
  }
};

/* Product Search */
export const PRODUCT_SEARCH_REQUEST = 'PRODUCT_SEARCH_REQUEST';
export const PRODUCT_SEARCH_SUCCESS = 'PRODUCT_SEARCH_SUCCESS';
export const PRODUCT_SEARCH_FAILURE = 'PRODUCT_SEARCH_FAILURE';

const productSearchRequest = (): any => {
  return {
    type: PRODUCT_SEARCH_REQUEST
  };
};

const productSearchSuccess = (searchResults, category, keyword): any => {
  return {
    type: PRODUCT_SEARCH_SUCCESS,
    payload: searchResults,
    searchedCategory: category,
    searchedKeyword: keyword
  };
};

const productSearchFailure = (error): any => {
  return {
    type: PRODUCT_SEARCH_FAILURE,
    error
  };
};

export const productSearch = (
  category: ?string,
  keyword: string,
  reportType?: number,
  currentPage?: number,
  clearSearch?: boolean
): any => async (dispatch: any, getState) => {
  try {
    dispatch(productSearchRequest());
    var endpoint = `/public/items/`;

    if (clearSearch) {
      keyword = '';
    }

    if (category) {
      endpoint += `category/${category}`;
    } else {
      endpoint += `category/0`;
    }
    if (keyword) {
      endpoint += `/search/${keyword}`;
    }
    endpoint += `?pagesize=12`;

    if (reportType && reportType > 0) {
      endpoint += `&reportTypeId=${reportType}`;
    }

    if (currentPage) {
      endpoint += `&page=${currentPage}`;
    }
    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL('v2', 'store')}${endpoint}`,
      isLiveAPICall: true,
      useToken: true
    });
    if (response instanceof Error) {
      throw response;
    }

    dispatch(productSearchSuccess(response, category, keyword));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(productSearchFailure(error));
    return error;
  }
};

/* Product Related Search */
export const PRODUCT_RELATED_SEARCH_REQUEST = 'PRODUCT_RELATED_SEARCH_REQUEST';
export const PRODUCT_RELATED_SEARCH_SUCCESS = 'PRODUCT_RELATED_SEARCH_SUCCESS';
export const PRODUCT_RELATED_SEARCH_FAILURE = 'PRODUCT_RELATED_SEARCH_FAILURE';

const productRelatedSearchRequest = (): any => {
  return {
    type: PRODUCT_RELATED_SEARCH_REQUEST
  };
};

const productRelatedSearchSuccess = (searchResults): any => {
  return {
    type: PRODUCT_RELATED_SEARCH_SUCCESS,
    payload: searchResults
  };
};

const productRelatedSearchFailure = (error): any => {
  return {
    type: PRODUCT_RELATED_SEARCH_FAILURE,
    error
  };
};

export const productRelatedSearch = (itemId: number): any => async (
  dispatch: any,
  getState
) => {
  try {
    dispatch(productRelatedSearchRequest());
    var endpoint = `/public/search/related/${itemId}`;

    endpoint += `?pagesize=3`; //only show up to 3 related items

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL('v2', 'store')}${endpoint}`,
      isLiveAPICall: true,
      useToken: true
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(productRelatedSearchSuccess(response));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(productRelatedSearchFailure(error));
    return error;
  }
};

/* All Product Search */
export const ALL_PRODUCT_SEARCH_REQUEST = 'ALL_PRODUCT_SEARCH_REQUEST';
export const ALL_PRODUCT_SEARCH_SUCCESS = 'ALL_PRODUCT_SEARCH_SUCCESS';
export const ALL_PRODUCT_SEARCH_FAILURE = 'ALL_PRODUCT_SEARCH_FAILURE';

const allProductSearchRequest = (): any => {
  return {
    type: ALL_PRODUCT_SEARCH_REQUEST
  };
};

const allProductSearchSuccess = (searchResults, category, keyword): any => {
  return {
    type: ALL_PRODUCT_SEARCH_SUCCESS,
    payload: searchResults,
    searchedCategory: category,
    searchedKeyword: keyword
  };
};

const allProductSearchFailure = (error): any => {
  return {
    type: ALL_PRODUCT_SEARCH_FAILURE,
    error
  };
};

export const allProductSearch = (): any => async (dispatch: any, getState) => {
  try {
    dispatch(allProductSearchRequest());
    var endpoint = `/public/items/`;

    endpoint += `?pagesize=99999`;

    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL('v2', 'store')}${endpoint}`,
      isLiveAPICall: true,
      useToken: true
    });
    if (response instanceof Error) {
      throw response;
    }

    dispatch(allProductSearchSuccess(response));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(allProductSearchFailure(error));
    return error;
  }
};
