//@flow
import React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import Breadcrumb from '../components/Breadcrumb';
import { connect } from 'react-redux';
import type { State as AppState, Dispatch } from '../types';
import { updateUserPassword } from '../actions/user';
import FlashMessage from './FlashMessage';
import UserPasswordSettingsForm from '../components/UserPasswordSettingsForm';

type PasswordForm = {
  newPassword: string,
  oldPassword: string,
};

type Props = {
  updateUserPassword: (formData: PasswordForm) => Promise<null>,
};

class PasswordSettings extends React.Component<Props> {
  handleUpdate = (formData) => {
    this.props.updateUserPassword(formData);
  };

  render() {
    return (
      <Container className="password-settings__form">
        <Grid>
          <Grid.Column width={12}>
            <Breadcrumb
              topLevelContext="My Account"
              currentContext={''}
              backLink="/my-account/details"
            />
            <div className="single-form">
              <FlashMessage />
              <h3 className="light-title">Update Password</h3>
              <UserPasswordSettingsForm handleFormSubmit={this.handleUpdate} />
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state: AppState, props) => ({
  user: state.auth.user.user,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateUserPassword,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordSettings): any);
