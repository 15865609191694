// @flow
import * as React from 'react';
import { setHomepageContent } from '../actions/pages/homepage';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import type { Dispatch, HomepageContent } from '../types';
import LoadingIndicator from '../components/LoadingIndicator';
import HomepageSearch from '../components/HomepageSearch';
import ReactHTMLParser from 'html-react-parser';

type Props = {
  page: ?HomepageContent,
  isFetching: boolean,
  setHomepageContent: () => Promise<*>,
};

class Home extends React.Component<Props> {
  componentDidMount() {
    this.props.setHomepageContent();
  }

  render() {
    const { isFetching, page } = this.props;
    if (isFetching || !page) {
      return <LoadingIndicator />;
    }

    return (
      <div className="ui">
        <HomepageSearch backgroundUrl={page.bannerImage} />
        <div className="callout-wrapper">
          <div className="ui container">
            {page.calloutsIntroText && (
              <div>{ReactHTMLParser(page.calloutsIntroText)}</div>
            )}
            <Grid>
              <Grid.Row>
                {page.callouts &&
                  page.callouts.map((item, i) => (
                    <Grid.Column
                      key={i}
                      mobile={6}
                      tablet={3}
                      computer={3}
                      className="callout-button-container"
                    >
                      <a href={item.clickThroughUrl}>
                        <div className="callout-button">
                          <img src={item.icon} alt="icon" />
                          <div>{item.title}</div>
                          <div>{item.isVideo}</div>
                        </div>
                      </a>
                    </Grid.Column>
                  ))}
              </Grid.Row>
            </Grid>
          </div>
        </div>
        <div className="ui container category-list">
          <div className="ui one column grid">
            <h2>Browse Reports By Category</h2>
          </div>
          <Grid stackable columns={5}>
            <Grid.Row>
              {page.browseReportsByCategory &&
                page.browseReportsByCategory.map((item, i) => (
                  <Grid.Column key={i}>
                    <a href={item.clickThroughUrl}>
                      <img src={item.image.url} alt="icon" />
                      <div>{item.title}</div>
                    </a>
                  </Grid.Column>
                ))}
            </Grid.Row>
          </Grid>
        </div>
        <div className="article-list">
          <div className="ui container">
            <div className="ui one column grid">
              <h2>Featured Reports</h2>
            </div>
            <Grid>
              <Grid.Row>
                {page.latestReports &&
                  page.latestReports.map((item, i) => (
                    <Grid.Column key={i} mobile={6} tablet={3} computer={3}>
                      <a href={item.clickThroughUrl}>
                        <img src={item.image.url} alt="icon" />
                        <h3 className="article-list__header">{item.title}</h3>
                        <div>{item.text}</div>
                      </a>
                    </Grid.Column>
                  ))}
              </Grid.Row>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  isFetching: state.homepageContent.isFetching,
  page: state.homepageContent,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setHomepageContent,
    },
    dispatch
  );

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(Home): React.AbstractComponent<Props>);
