// @flow
import type { Action, Product, Dispatch, ThunkAction } from '../../types';

export const SET_CART = 'SET_CART';

const setCartAction = (cart: Array<Product>): Action => {
  return {
    type: SET_CART,
    payload: cart
  };
};

export const setCart = (cart: Array<Product>): ThunkAction => async (
  dispatch: Dispatch,
  getState
) => {
  dispatch(setCartAction(cart));
};
