//@flow
import React from 'react';
import { Label } from 'semantic-ui-react';
import type {Node} from "React";

type Props = {
  title: string
};

class SearchResultItem extends React.Component<Props> {
  render(): Node {
    const { title } = this.props;
    return <Label content={title} />;
  }
}

export default SearchResultItem;
