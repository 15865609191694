// @flow
import * as React from 'react';
import SVG from 'react-inlinesvg';

type Props = {
  src?: string,
  colour?: string,
  alt?: string,
  title?: string
};

class LoadSVG extends React.Component<Props> {
  render(): null | React.Element<"img"> | React.Element<"span"> {
    const { src, colour, alt, title } = this.props;

    if (!src) {
      return null;
    }
    const extension = src.split('.').pop();

    switch (extension) {
      case 'svg':
        return (
          <span style={{ fill: colour, stroke: colour }}>
            <SVG src={src} aria-label={alt} />
          </span>
        );

      case 'png':
      case 'jpg':
      case 'jpeg':
        return <img src={src} alt={alt} title={title} />;
      default:
        return null;
    }
  }
}

export default LoadSVG;
