// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch, Action } from '../../types';
import { callAPI } from '../../middleware';
import { getApiBaseURL } from 'roy-morgan-auth';

export const HOMEPAGE_SEARCH_REQUEST = 'HOMEPAGE_SEARCH_REQUEST';
export const HOMEPAGE_SEARCH_SUCCESS = 'HOMEPAGE_SEARCH_SUCCESS';
export const HOMEPAGE_SEARCH_FAILURE = 'HOMEPAGE_SEARCH_FAILURE';

const homepageSearchRequest = (): Action => {
  return {
    type: HOMEPAGE_SEARCH_REQUEST
  };
};

const homepageSearchSuccess = (searchResults): Action => {
  return {
    type: HOMEPAGE_SEARCH_SUCCESS,
    payload: searchResults
  };
};

const homepageSearchFailure = (error): Action => {
  return {
    type: HOMEPAGE_SEARCH_FAILURE,
    error
  };
};

export const homepageSearch = (query: string): ThunkAction => async (
  dispatch: Dispatch,
  getState
) => {
  try {
    dispatch(homepageSearchRequest());
    const response = await callAPI({
      method: 'GET',
      endpoint: `${getApiBaseURL(
        'v2',
        'store'
      )}/public/search/suggest/?text=${query}`,
      isLiveAPICall: true,
      useToken: true
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(homepageSearchSuccess(response));

    return response;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(homepageSearchFailure(error));
    return error;
  }
};
