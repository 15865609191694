// @flow
import * as React from 'react';
import TreeNode from './TreeNode';
import NewsletterSignup from './NewsletterSignup';
import { extractCategories } from '../helpers/transformers';
import URLSearchParams from '@ungap/url-search-params';

type Node = {
  id: number,
  title: string,
  slug: string,
  children?: Array<Node>
};

type Category = {
  children: Array<number>,
  id: number,
  levelId: number,
  parentId: number,
  slug: string,
  title: string
};

type Props = {
  treeNav: Array<Node>,
  treeflat: {
    entities: {
      categories: {
        [key: string]: Category
      }
    },
    result: Array<number>
  },
  match: Object,
  location: any,
  productSearch: Function,
  reportTypeId: number
};

type State = {
  activePage: number,
  pageLoadCheck: ?Object
};

class TreeNav extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // Todo: move the "activePage" variable to the store instead of this component
    this.state = {
      pageLoadCheck: null,
      activePage: -1
    };

    // Check for a search keyword
    const urlParams = new URLSearchParams(props.location.search);
    const keywordQuery = urlParams.get('keyword');
    const searchKeyword =
      keywordQuery && keywordQuery.length ? keywordQuery : '';

    // Get all the categories from the route, if we are navigating here from another page
    const {
      category,
      subcategory,
      productcategory,
      productsubcategory,
      categoryLevel5,
      categoryLevel6
    } = this.props.match.params;
    let slugToCheck = '';

    slugToCheck += category ? category : '';
    slugToCheck += subcategory ? '/' + subcategory : '';
    slugToCheck += productcategory ? '/' + productcategory : '';
    slugToCheck += productsubcategory ? '/' + productsubcategory : '';
    slugToCheck += categoryLevel5 ? '/' + categoryLevel5 : '';
    slugToCheck += categoryLevel6 ? '/' + categoryLevel6 : '';

    if (slugToCheck && slugToCheck.length) {
      const selectedCategory = (Object.values(
        this.props.treeflat.entities.categories
      ): any).find((category: Category) => category.slug === slugToCheck);

      if (selectedCategory && selectedCategory.id) {
        const extracted = extractCategories(
          this.props.treeflat,
          selectedCategory.id
        );
        this.state = {
          pageLoadCheck: extracted,
          activePage: selectedCategory.id
        };

        // load initial product list
        props.productSearch(
          selectedCategory.id,
          searchKeyword,
          this.props.reportTypeId,
          null,
          true
        );
      }
    } else {
      // get all products as none have been selected
      props.productSearch(null, searchKeyword);
    }
  }

  setActivePage: ((categoryId: number) => void) = (categoryId: number) => {
    this.setState({
      activePage: categoryId,
      pageLoadCheck: null
    });
  };

  render(): React.Element<"div"> {
    const { treeNav, productSearch, reportTypeId } = this.props;
    const { activePage, pageLoadCheck } = this.state;

    return (
      <div className="tree-nav-wrapper">
        <h2>Categories</h2>
        <ul className="tree-nav">
          {treeNav.map((item: Node, i) => (
            <TreeNode
              node={item}
              key={i}
              parentSlug="/products"
              pageLoadCheck={pageLoadCheck}
              currentActivePage={activePage}
              setActivePage={this.setActivePage}
              productSearch={productSearch}
              reportTypeId={reportTypeId}
            />
          ))}
        </ul>
        <NewsletterSignup />
      </div>
    );
  }
}

export default TreeNav;
