import { normalize, schema } from 'normalizr';

/**
 * Recursively loop through all children and sure all slugs
 * are lowercase, including potential children.
 *
 * @param {Object} treeNode
 */
const transformNode = treeNode => {
  return {
    ...treeNode,
    children: treeNode.children
      ? treeNode.children.map((child, cIndex) => transformNode(child))
      : null,
    slug: treeNode && treeNode.slug && treeNode.slug.toLowerCase()
  };
};

/**
 * Format a list of categories & links
 *
 * @param {Array} tree
 */
export const transformTree = tree => {
  return tree.map((n, i) => transformNode(n));
};

/**
 * Flatten the tree data so it is easier to manage
 * categories and products within them.
 *
 * @param {Object} flatTree
 */
export const normalizeTree = tree => {
  // define the recursive schema for our data to be flattened
  const category = new schema.Entity('categories');
  const children = new schema.Array(category);
  category.define({ children });

  // convert into a flattened array for easier reading
  const normalizedData = normalize(tree, children);
  return normalizedData;
};

/**
 * Given a single category ID, find all the possible parent categories
 * and return them along with the passed category.
 *
 * TODO: Refactor this more inline with js/react best practice.
 */
export const extractCategories = (treeflat, selectedCategoryID) => {
  const displayCategories = [];

  if (treeflat) {
    const chosenCategory = treeflat.entities.categories[selectedCategoryID];

    if (chosenCategory) {
      if (chosenCategory.parentId > 0) {
        findParent(treeflat, chosenCategory, displayCategories);
      } else {
        displayCategories.push(chosenCategory);
      }
    }
  }

  return displayCategories;
};

const findParent = (treeflat, categoryItem, categoryList) => {
  categoryList.push(categoryItem);
  if (categoryItem.parentId > 0) {
    const foundCategory = treeflat.entities.categories[categoryItem.parentId];
    return findParent(treeflat, foundCategory, categoryList);
  } else {
    return categoryList;
  }
};
