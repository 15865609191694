// @flow

export const ONLINE_STORE_PRODUCT = 9;

export const formatCurrency = (
  num: number,
  locale: string = 'en-US',
  currency: string = 'USD',
  minimumFractionDigits: number = 2
): number | string => {
  if (isNaN(num)) {
    return num;
  }
  return num.toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits
  });
};
