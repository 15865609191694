// @flow
import React from 'react';
import { Grid, Form, Button } from 'semantic-ui-react';
import StaticField from './StaticField';
import { Link } from 'react-router-dom';
import type {Node} from "React";

type User = {
  name?: string,
  email?: string,
  phone?: string
};

type Props = {
  user: User,
  handleFormSubmit: (formData: User) => void
};

type State = {
  formData: User
};

class InviteUserFormFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      formData: {
        name:
          this.props.user && this.props.user.name ? this.props.user.name : '',
        email:
          this.props.user && this.props.user.email ? this.props.user.email : '',
        phone:
          this.props.user && this.props.user.phone ? this.props.user.phone : ''
      }
    };
  }

  handleInputChange: ((event: SyntheticInputEvent<HTMLInputElement>) => void) = (event: SyntheticInputEvent<HTMLInputElement>) => {
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  render(): Node {
    const { name, email, phone } = this.state.formData;
    const { handleFormSubmit } = this.props;
    return (
      <Form
        onSubmit={() => {
          handleFormSubmit(this.state.formData);
        }}
        className="my-details__form user-settings__form"
      >
        <Grid columns={2} stackable>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Name"
                name="name"
                value={name}
                onChange={this.handleInputChange}
                type="text"
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                label="Phone"
                name="phone"
                value={phone}
                onChange={this.handleInputChange}
                type="phone"
                required
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <StaticField label="Email" value={email} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16} className="user-settings__form-btns">
              <Link to={'/settings/password'}>Update Password</Link>
              <Button
                className="organisation-form__submit user-settings__form-submit"
                type="submit"
              >
                Save Changes
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

export default InviteUserFormFields;
